export interface AuthStateStore {
    getAuthState(): AuthState | null
    submitAuthState(state: AuthState): Promise<void>
    isAuthenticated(): boolean
    clearAuthState(): Promise<void>
}

export enum AuthStorageKey {
    ACCESS_TOKEN = 'auth:accessToken',
    TOKEN_TYPE = 'auth:tokenType',
    REFRESH_TOKEN = 'auth:refreshToken',
    EXPIRES_AT = 'auth:expiresAt',
}

/**
 * Note that we are using the same interface as the AuthPayload type from the GraphQL API schema.
 * We just transform the keys to CamelCase as we are used to work with that in the frontend.
 * We create new value called expiresAt which is a Date.
 */
export interface AuthState {
    readonly accessToken: string
    readonly tokenType: string
    readonly refreshToken: string
    readonly expiresAt: Date
}
