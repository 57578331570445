import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { environment } from '@app-environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { GraphQLModule } from './modules/grapqhl/graphql.module'
import packageJson from '../../package.json'
import { Environment } from '@app-environments/types'
import { ShortcodeService } from '@app-services/shortcode/shortcode.service'
import { AuthService } from '@app-services/auth/auth.service'
import { ThemingService } from '@app-services/theming/theming.service'
import { Router, Scroll } from '@angular/router'
import { delay, filter } from 'rxjs'
import { ViewportScroller } from '@angular/common'
import { StockService } from '@app-services/stock/stock.service'

Bugsnag.start({
    apiKey: '71135c9c4e41b804738f6f255485f0cc',
    releaseStage: environment.name,
    appVersion: packageJson.version,
    redactedKeys: ['code', 'password'],
    enabledReleaseStages: [
        Environment.Acceptance,
        Environment.Production,
    ],
})

export function errorHandlerFactory(): ErrorHandler {
    return new BugsnagErrorHandler()
}

export function translateLoaderFactory(http: HttpClient): TranslateLoader {
    return new TranslateHttpLoader(http)
}

export function appInitializerFactory(
    shortcodeService: ShortcodeService,
    translateService: TranslateService,
    authService: AuthService,
    stockService: StockService,
    themingService: ThemingService,
) {
    return async () => {
        translateService.setDefaultLang('en')
        // Initializing first the auth service before all other services
        authService.initialize()
        await Promise.all([
            shortcodeService.initialize(),
            stockService.initialize(),
        ])
        themingService.initialize()
    }
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        GraphQLModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [
                    HttpClient,
                ],
            },
        }),
    ],
    providers: [
        {
            provide: ErrorHandler,
            useFactory: errorHandlerFactory,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            multi: true,
            deps: [
                ShortcodeService,
                TranslateService,
                AuthService,
                StockService,
                ThemingService,
            ],
        },
    ],
    bootstrap: [
        AppComponent,
    ],
})
export class AppModule {
    constructor(router: Router, viewportScroller: ViewportScroller) {
        router.events.pipe(
            filter((e): e is Scroll => e instanceof Scroll),
            delay(0),
        ).subscribe(e => {
            if (e.position) viewportScroller.scrollToPosition(e.position!)
            else if (e.anchor) viewportScroller.scrollToAnchor(e.anchor!)
            else viewportScroller.scrollToPosition([0, 0])
        })
    }
}
