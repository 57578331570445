import { NgModule } from '@angular/core'
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core'
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, ApolloModule } from 'apollo-angular'
import { environment } from '@app-environments/environment'
import { createApiAuthLink, createApiHttpLink, createApiLogLink, graphQlApiUrl } from './graphql.module.lib'
import { AuthStorageService } from '@app-services/auth-storage/auth-storage.service'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

function createApollo(authStorageService: AuthStorageService): ApolloClientOptions<any> {
    return {
        cache: new InMemoryCache(),
        link: ApolloLink.from([
            createApiAuthLink(authStorageService),
            createApiLogLink(),
            createApiHttpLink(environment),
        ]),
    }
}

function createPubsubApollo(): Record<string, ApolloClientOptions<any>> {
    return {
        pubsub: {
            name: 'pubsub',
            link: new GraphQLWsLink(
                createClient({
                    url: graphQlApiUrl(environment.pubsub),
                }),
            ),
            cache: new InMemoryCache(),
        },
    }
}

@NgModule({
    imports: [
        ApolloModule,
    ],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [AuthStorageService],
        },
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: createPubsubApollo,
            deps: [],
        },
    ],
})
export class GraphQLModule { }
