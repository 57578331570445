import { Nil } from '@app-types/common.types'
import { isNil, not } from 'ramda'

export function isString(x: unknown): x is string {
    return typeof x === 'string'
}

export function isNumber(x: unknown): x is number {
    return typeof x === 'number'
}

export function* idGenerator(prefix: string): IterableIterator<string> {
    let index = 0
    while (true) {
        yield `${prefix}-${index++}`
    }
}

export function isNotNil<T>(valueToCheck: T | Nil): valueToCheck is T {
    return not(isNil(valueToCheck))
}
