import { Injectable } from '@angular/core'
import {
    DailyMenuItemFragment,
} from '@app-graphql/api-schema'
import { StockUpdateFragment, StockUpdatesSubscriptionService } from '@app-graphql/pubsub-schema'
import { isNil } from 'ramda'
import { BehaviorSubject, EMPTY, Observable, switchMap } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { AuthService } from '@app-services/auth/auth.service'
import { distinctUntilChangedEquals, shareReplayOne } from '@app-lib/rxjs.lib'
import { StockFactoryService } from '@app-services/stock-factory/stock-factory.service'
import { Stock } from '@app-services/stock/stock.service.types'

type MenuEntryOccurrenceID = string & {}
type StockMap = Record<MenuEntryOccurrenceID, Stock>

@Injectable({
    providedIn: 'root',
})
export class StockService {

    private readonly stockMap$$ = new BehaviorSubject<StockMap>({})

    constructor(
        private readonly auth: AuthService,
        private readonly stockUpdatesSubscriptionService: StockUpdatesSubscriptionService,
        private readonly factory: StockFactoryService,
    ) {
    }

    public async initialize(): Promise<void> {
        this.auth
            .getUserObservable()
            .pipe(
                map((user) => user.user?.client.registrationShortcode),
                distinctUntilChangedEquals(),
                switchMap((shortcode) => {
                    return isNil(shortcode)
                        ? EMPTY
                        : this.watchClientStockUpdates(shortcode)
                }),
            )
            .subscribe((update) => this.registerPubsubStockUpdate(update))
    }

    /**
     * Returns an observable that tracks the stock status for the given menu item over time.
     */
    public watchStock(menuItem: DailyMenuItemFragment): Observable<Stock> {
        return this.stockMap$$.pipe(
            map((stockMap) => stockMap[menuItem.sources.menuEntryOccurrence] ?? this.registerInitialStock(menuItem)),
            distinctUntilChangedEquals(),
            shareReplayOne(),
        )
    }

    // ------------------------------------------------------------------------------
    //      Stock data registration (private)
    // ------------------------------------------------------------------------------

    private registerPubsubStockUpdate(update: StockUpdateFragment): Stock {
        return this.registerStockState(
            this.factory.fromPubsubStockUpdate(update),
        )
    }

    private registerInitialStock(item: DailyMenuItemFragment): Stock {
        return this.registerStockState(
            this.factory.fromDailyMenuItem(item),
        )
    }

    private registerStockState<T extends Stock>(stock: T): T {
        this.stockMap$$.next(
            { ...this.stockMap$$.getValue(), [stock.menuEntryOccurrenceID]: stock },
        )

        return stock
    }

    // ------------------------------------------------------------------------------
    //      Initialization
    // ------------------------------------------------------------------------------

    private watchClientStockUpdates(clientShortcode: string): Observable<StockUpdateFragment> {
        return this.stockUpdatesSubscriptionService
            .subscribe({
                clientID: clientShortcode,
            })
            .pipe(
                map((stockResult) => stockResult.data?.stockUpdates),
                filter((update): update is StockUpdateFragment => ! isNil(update)),
            )
    }
}
