import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('@app-pages/home/home.module').then((m) => m.HomeModule),
    },
    {
        path: ':shortcode',
        loadChildren: () => import('@app-pages/client/client.module').then((m) => m.ClientModule),
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
