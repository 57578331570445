import { Injectable } from '@angular/core'
import { ClientThemeFragment } from '@app-graphql/api-schema'
import { isNotNil } from '@app-lib/common.lib'
import { ShortcodeService } from '@app-services/shortcode/shortcode.service'
import { filter, map, Observable, switchMap } from 'rxjs'
import { Colord, colord, extend } from 'colord'
import a11yPlugin from 'colord/plugins/a11y'

extend([a11yPlugin])

@Injectable({
    providedIn: 'root',
})
export class ThemingService {
    public clientTheming$: Observable<ClientThemeFragment>

    constructor(private readonly shortcodeService: ShortcodeService) { }

    public initialize(): void {
        this.clientTheming$ = this.shortcodeService.shortcodeInfo$.pipe(
            filter((shortcode) => shortcode.isValid),
            switchMap(() => this.shortcodeService.client$),
            filter(isNotNil),
            map(client => client.themes.web),
        )

        this.clientTheming$.subscribe((theme) => {
            this.setClientThemeColors(theme)
        })
    }

    protected setClientThemeColors(theme: ClientThemeFragment): void {
        this.createColorPalette('primary', colord(theme.colorPrimary))
        this.createColorPalette('primary-contrast', colord(theme.colorPrimaryContrast))
        this.createColorPalette('secondary', colord(theme.colorSecondary))
        this.createColorPalette('secondary-contrast', colord(theme.colorSecondaryContrast))
        this.createColorPalette('tertiary', colord(theme.colorTertiary))
        this.createColorPalette('tertiary-contrast', colord(theme.colorTertiaryContrast))
        this.createColorPalette('success', colord(theme.colorSuccess))
        this.createColorPalette('success-contrast', colord(theme.colorSuccessContrast))
        this.createColorPalette('warning', colord(theme.colorWarning))
        this.createColorPalette('warning-contrast', colord(theme.colorWarningContrast))
        this.createColorPalette('error', colord(theme.colorError))
        this.createColorPalette('error-contrast', colord(theme.colorErrorContrast))
        this.createColorPalette('text-dark', colord(theme.colorTextDark))
        this.createColorPalette('text-dark-contrast', colord(theme.colorTextLight))
        this.createColorPalette('text-light', colord(theme.colorTextLight))
        this.createColorPalette('text-light-contrast', colord(theme.colorTextDark))
    }

    protected createColorPalette(colorKey: string, color: Colord): void {
        if (! color.isValid()) return
        document.documentElement.style.setProperty(`--color-${colorKey}`, color.toHex())
        document.documentElement.style.setProperty(`--color-${colorKey}-rgb`, this.createRGBListString(color))
    }

    protected createRGBListString(color: Colord): string {
        const { r, g, b } = color.toRgb()
        return `${r}, ${g}, ${b}`
    }
}
