import { Injectable } from '@angular/core'
import { StockUpdateFragment } from '@app-graphql/pubsub-schema'
import { DailyMenuItemFragment } from '@app-graphql/api-schema'
import { Stock } from '@app-services/stock/stock.service.types'

@Injectable({
    providedIn: 'root',
})
export class StockFactoryService {

    /**
     * Creates a normalized {@link Stock} object from the given {@link StockUpdateFragment Pub-sub stock update}.
     */
    public fromPubsubStockUpdate(update: StockUpdateFragment): Stock {
        return Object.freeze({
            menuEntryOccurrenceID: update.menuEntryOccurrenceID,
            amountTotalInitial: update.amountTotalInitial ?? Infinity,
            amountTotalCorrection: update.amountTotalCorrection,
            amountTotal: update.amountTotal ?? Infinity,
            amountClaimed: update.amountClaimed,
            amountIssued: update.amountIssued,
            amountReserved: update.amountReserved,
            amountAvailable: update.amountAvailable ?? Infinity,
            amountIssuable: update.amountIssuable ?? Infinity,
            isConstrained: update.isConstrained,
            isExhausted: update.isExhausted,
            isCorrected: update.isCorrected,
        })
    }

    /**
     * Creates a normalized {@link Stock} object for the given {@link DailyMenuItemFragment menu-item}.
     */
    public fromDailyMenuItem(menuItem: DailyMenuItemFragment): Stock {
        return Object.freeze({
            menuEntryOccurrenceID: menuItem.sources.menuEntryOccurrence,
            amountTotalInitial: menuItem.stock.amountTotalInitial ?? Infinity,
            amountTotalCorrection: menuItem.stock.amountTotalCorrection,
            amountTotal: menuItem.stock.amountTotal ?? Infinity,
            amountClaimed: menuItem.stock.amountClaimed,
            amountIssued: menuItem.stock.amountIssued,
            amountReserved: menuItem.stock.amountReserved,
            amountAvailable: menuItem.stock.amountAvailable ?? Infinity,
            amountIssuable: menuItem.stock.amountIssuable ?? Infinity,
            isConstrained: menuItem.stock.isConstrained,
            isExhausted: menuItem.stock.isExhausted,
            isCorrected: menuItem.stock.isCorrected,
        })
    }
}
