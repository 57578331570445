import { Injectable } from '@angular/core'
import { isNil } from 'ramda'
import { AuthState, AuthStorageKey } from './auth-storage.types'
import { parseISO, formatISO } from 'date-fns'

@Injectable({
    providedIn: 'root',
})
export class AuthStorageService {
    private authState: AuthState | null = null

    public initialize(): void {
        const initialState = this.readPersistedAuthState()

        if (initialState !== null) this.submitAuthState(initialState)
        else this.clearAuthState()
    }

    public getAuthState(): AuthState | null {
        return this.authState
    }

    public isAuthenticated(): boolean {
        return this.authState !== null
    }

    /**
     * Submits the given auth-state to local storage and then change the authState variable.
     */
    public submitAuthState({ accessToken, tokenType, refreshToken, expiresAt }: AuthState): void {
        localStorage.setItem(AuthStorageKey.ACCESS_TOKEN, accessToken)
        localStorage.setItem(AuthStorageKey.TOKEN_TYPE, tokenType)
        localStorage.setItem(AuthStorageKey.REFRESH_TOKEN, refreshToken)
        localStorage.setItem(AuthStorageKey.EXPIRES_AT, formatISO(expiresAt))

        this.authState = {
            accessToken,
            tokenType,
            refreshToken,
            expiresAt,
        }
    }

    /**
     * Removes all AuthState information from local storage and set authState to null.
     */
    public clearAuthState(): void {
        Object.values(AuthStorageKey).forEach(key => { localStorage.removeItem(key) })

        this.authState = null
    }

    /**
     * Tests if the given value is the shape of a full authentication state object, as is
     * locally stored on the app device.
     */
    public isValidAuthState(state: any): state is AuthState {
        return typeof state === 'object'
            && typeof state?.accessToken === 'string'
            && typeof state?.tokenType === 'string'
            && typeof state?.refreshToken === 'string'
            && state?.expiresAt instanceof Date
    }

    /**
     * Reads and returns the locally stored AuthState data. Returns null instead if a
     * fully valid state object cannot be constructed from the stored data.
     */
    private readPersistedAuthState(): AuthState | null {
        const storageExpiresAt = this.getItemFromStorage(AuthStorageKey.EXPIRES_AT)

        if (isNil(storageExpiresAt)) return null

        const state: Partial<AuthState> = {
            accessToken: this.getItemFromStorage(AuthStorageKey.ACCESS_TOKEN),
            tokenType: this.getItemFromStorage(AuthStorageKey.TOKEN_TYPE),
            refreshToken: this.getItemFromStorage(AuthStorageKey.REFRESH_TOKEN),
            expiresAt: parseISO(storageExpiresAt),
        }

        return this.isValidAuthState(state) ? state : null
    }

    private getItemFromStorage(key: string): string | undefined {
        return localStorage.getItem(key) || undefined
    }
}
